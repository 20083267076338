/*! Generated by Rohil Visariya on November 19, 2019 */

@font-face {
  font-family: 'Rubik';
  src: local('Rubik Light'), local('Rubik-Light'), local('RubikLight'),
  url('rubik-light-webfont.woff2') format('woff2'),
  url('rubik-light-webfont.woff') format('woff'),
  url('rubik-light-webfont.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Rubik';
  src: local('Rubik'), local('RubikRegular'), local('Rubik Regular'), local('Rubik-Regular'),
  url('rubik-regular-webfont.woff2') format('woff2'),
  url('rubik-regular-webfont.woff') format('woff'),
  url('rubik-regular-webfont.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Rubik';
  src: local('Rubic Italic'), local('Rubic-Italic'), local('RubicItalic'),
  url('rubik-italic-webfont.woff2') format('woff2'),
  url('rubik-italic-webfont.woff') format('woff'),
  url('rubik-italic-webfont.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}


@font-face {
  font-family: 'Rubik';
  src: local('Rubik Medium'), local('Rubik-Medium'), local('RubikMedium'),
  url('rubik-medium-webfont.woff2') format('woff2'),
  url('rubik-medium-webfont.woff') format('woff'),
  url('rubik-medium-webfont.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: local('Rubik Bold'), local('Rubik-Bold'), local('RubikBold'),
  url('rubik-bold-webfont.woff2') format('woff2'),
  url('rubik-bold-webfont.woff') format('woff'),
  url('rubik-bold-webfont.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
