.gx-app-login-main-content{
  border-radius: 5px !important;
}
.gx-app-login-container{
  max-width:400px !important;
}
.gx-app-login-content{
  width:100% !important;
}
.gx-app-logo{
  width:66.67% !important;
  margin:1em auto 2em !important;
}
.gx-app-login-header{
  padding-top:1em !important;
}

